import React, { useState, useEffect } from 'react'
import { s, colors, alpha } from '../style'
import cleveruikitlogo from '../assets/paths/cleveruikitlogo'
import mockupbar from '../assets/paths/mockupbar'
import Dot from '@material-ui/icons/FiberManualRecord'
import Images from '../components/images'
import { Collapse } from '@material-ui/core'
import ArrowDown from '@material-ui/icons/ExpandMore'

const { productSection } = require('../config')

const config = {
  width: 350,
  height: 180,
}

const ProductSection = ({ language }) => {
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(0)
  const [countDirection, setCountDirection] = useState(true)

  const { text, tiles, mockup, label } = productSection

  useEffect(() => {
    const timer = setTimeout(() => {
      if (count === 1) {
        setCountDirection(true)
      }
      if (count === 4) {
        setCountDirection(false)
      }
      setCount(countDirection ? count + 1 : count - 1)
    }, 2000)
    return () => clearTimeout(timer)
  }, [count])

  useEffect(() => {
    const timer2 = setTimeout(() => {
      setExpand(expand + 1)
    }, 3300)
    return () => clearTimeout(timer2)
  }, [expand])

  return (
    <div css={sOuterContainer}>
      <div css={[sInnerContainers, sLeftContainer]}>
        <div css={sLabel}>{label[language]}</div>
        <div css={sLogo}>{cleveruikitlogo}</div>
        <p css={sText}>{text[language]}</p>
      </div>
      {/* <div css={sShine} /> */}
      <div css={[sInnerContainers, sRightContainer]}>
        <div css={sMockup}>
          <div css={sPhoneOuter}>
            <div css={sPhoneInner}>
              <div css={sStatusBar}>
                <span css={sStatusBar.time}>
                  {new Date().getHours()}:{new Date().getMinutes() < 10 && 0}
                  {new Date().getMinutes()}
                </span>
                <div css={sDrop}>
                  <div css={sDrop.reflect1} />
                  <div css={sDrop.reflect2} />
                </div>
                {mockupbar}
              </div>
              {/* <div css={sMenu}></div> */}
              <div css={sTilesContainer}>
                {Object.values(tiles).map((tile, id) => (
                  <div
                    key={id}
                    css={[
                      sTile,
                      {
                        boxShadow: `4px 4px 16px ${tile.colorBack.concat(
                          alpha[0]
                        )}`,
                        background: `linear-gradient(to right, ${tile.colorBack.concat(
                          alpha[30]
                        )}, ${tile.colorBack.concat(
                          alpha[100]
                        )} 40%, ${tile.colorBack.concat(alpha[100])})`,
                        color: tile.colorFront,
                        position: 'absolute',
                        top: 0.1 * config.height,
                        transition:
                          'transform 0.6s cubic-bezier(1,.07,.27,.75), opacity 0.6s ease-in-out',
                        opacity: id === count ? 1 : 0,
                        left: id * config.width + 32 * (id + 1),
                        transform: `translateX(-${
                          count * config.width + 32 * (count + 1)
                        }px) scale(${id === count ? 1.0 : 0.8})`,
                      },
                    ]}
                  >
                    <Images image={tile.photo} styleProp={sTile.image} />
                    <div
                      css={[
                        sTile.gradient,
                        {
                          background: `linear-gradient(to right, ${tile.colorBack.concat(
                            alpha[100]
                          )} 0%, ${tile.colorBack.concat(alpha[100])} 20%, 
                                    ${tile.colorBack.concat(
                                      alpha[30]
                                    )} 50%, ${tile.colorBack.concat(
                            alpha[0]
                          )} 100%)`,
                        },
                      ]}
                    />
                    <div css={sTile.left}>
                      <div>
                        <p css={sTile.title}>{tile.title}</p>
                        <div css={[sCenter]}>
                          {tile.subtitle.map((text, id) => (
                            <div key={id}>
                              {id > 0 && <Dot css={sTile.dot} />}
                              <span key={id} css={sTile.subtitle}>
                                {text}
                              </span>
                            </div>
                          ))}
                        </div>
                        <p css={sTile.description}>{tile.description}</p>
                      </div>
                      <div
                        css={[
                          sTile.btn,
                          {
                            color: tile.colorFront.concat(alpha[60]),
                            backgroundColor: tile.colorBack.concat(alpha[60]),
                          },
                        ]}
                      >
                        {tile.button}
                      </div>
                    </div>
                  </div>
                ))}
                <div css={sDots}>
                  {Object.keys(tiles).map((dot, id) => (
                    <Dot
                      key={id}
                      css={[sDots.dot, { opacity: id === count ? 1 : 0.3 }]}
                    />
                  ))}
                </div>
              </div>

              <div css={sExtendables}>
                {mockup.expandables.map((section, id) => (
                  <div
                    key={id}
                    css={[
                      sExtendables.container,
                      expand % 2 === id && sExtendables.container.active,
                    ]}
                  >
                    <div css={sExtendables.topPart}>
                      <ArrowDown
                        css={[
                          { transition: 'transform 0.3s ease-out' },
                          expand % 2 === id
                            ? { transform: 'rotate(180deg)' }
                            : { transform: 'rotate(0deg)' },
                        ]}
                      />
                      <p className="extandablesTitle">{section.title}</p>
                    </div>
                    <Collapse in={expand % 2 === id}>
                      <p className="extandablesText">{section.text}</p>
                    </Collapse>
                  </div>
                ))}
              </div>
              <Images image={mockup.image} styleProp={sMockupImage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const sMockupImage = {
  position: 'absolute',
  bottom: -120,
  right: -90,
  width: 180,
  '@media screen and (maxWidth: 599px)': {
    right: -48,
  },
  height: 'auto',
  borderRadius: '1.5rem',
  boxShadow: `0px 16px 64px ${colors.black.concat(alpha[30])}`,
}

const sExtendables = {
  position: 'absolute',
  top: 340,
  [s.xs]: {
    left: -48,
  },
  left: -128,
  background: `linear-gradient(to bottom right, ${colors.white} 0%, ${colors.white} 25%, #CBCBCB 100%)`,
  boxShadow: `0px 16px 96px ${colors.black.concat(alpha[30])}`,
  borderRadius: '1.5rem',
  padding: '1.25rem 1.25rem 1.5rem',
  color: colors.blue,
  width: 414,

  container: {
    transition: 'opacity 0.3s ease-in-out, background-color 0.3s ease-in-out',
    borderRadius: '1.5rem',
    fontFamily: 'Gilroy',
    fontWeight: 300,
    opacity: 0.3,
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 1.5rem 1.5rem',
    backgroundColor: colors.blue.concat(alpha[0]),
    '.extandablesTitle': {
      fontFamily: 'inherit',
      marginLeft: '0.5rem',
      fontSize: '1.25rem',
      WebkitTextStroke: `1px ${colors.blue}`,
    },
    '.extandablesText': {
      fontSize: 13,
      opacity: 0.6,
      paddingTop: '1rem',
    },
    active: {
      opacity: 1,
      backgroundColor: colors.blue.concat(alpha[8]),
    },
  },

  topPart: {
    display: 'flex',
    alignItems: 'center',
  },
}

const sOuterContainer = {
  marginTop: '1.5rem',
  [s.sm_down]: { marginTop: '4rem' },
  background: `linear-gradient(to bottom right, ${colors.white.concat(
    alpha[100]
  )}, ${colors.white.concat(alpha[75])})`,
  display: 'flex',
  flexWrap: 'wrap',
  boxShadow: `0px 0px 96px ${colors.black.concat(alpha[60])}`,
}

const sInnerContainers = {
  position: 'relative',
  [s.sm_down]: { width: '100vw', minHeight: '70vh' },
  [s.md]: { height: 680 },
}

const sLeftContainer = {
  width: '40%',
  // backgroundColor: 'rgba(0,255,0,0.1)',
  display: 'flex',
  justifyContent: 'center',
  [s.sm_down]: { alignItems: 'center' },
  [s.md]: { alignItems: 'flex-end' },
  flexDirection: 'column',
}

const sRightContainer = {
  width: '60%',
  // backgroundColor: 'rgba(255,0,255,0.1)',
}

// const sShine = {
//   opacity: 0.3,
//   position: 'absolute',
//   width: '100%',
//   height: '100%',
//   [s.md]: { height: 680 },
//   background:
//     'linear-gradient(to right, rgba(255,255,255,0) 0% , rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.4) 25%, rgba(255,255,255,1) 50% , rgba(255,255,255,0) 75% , rgba(255,255,255,0) 100%)',
//   backgroundSize: '200vw',
//   animation: 'shine 2s forwards linear infinite',
//   '@keyframes shine': {
//     from: { backgroundPosition: '-100vw 0' },
//     to: { backgroundPosition: '100vw 0' },
//   },
// }

const sLabel = {
  position: 'absolute',
  top: '2rem',
  left: '2rem',
  borderRadius: '2rem',
  width: 'max-content',
  fontSie: 20,
  fontWeight: 700,
  WebkitTextStroke: '0.5px white',
  letterSpacing: 1,
  color: 'white',
  padding: '0.75rem 2rem',
  background: 'linear-gradient(to bottom right, #FFABAB, #FF5757)',
}

const sLogo = {
  // position: 'absolute',
  // top: '35%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  svg: {
    [s.sm_down]: { width: 420, marginTop: '6rem' },
    [s.xs]: { width: 300 },
    [s.md]: { width: 420 },
    width: 420,
    height: 'auto',
  },
}

const sText = {
  marginTop: '2rem',
  [s.sm_down]: { padding: '0 2rem' },
  [s.md]: { width: 420 },
  fontSize: '1.125rem',
  fontWeight: 300,
  textAlign: 'right',
  lineHeight: '1.6em',
  color: colors.black.concat(alpha[80]),
}

const sMockup = {
  [s.xs]: { transform: 'scale(0.75)', height: 600 },
}
const sPhoneOuter = {
  borderRadius: '4rem 4rem 0 0',
  background: `linear-gradient(to bottom right, ${colors.white.concat(
    alpha[80]
  )}, ${colors.white.concat(alpha[0])})`,
  boxShadow: `32px 32px 48px ${colors.black.concat(alpha[30])}`,
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, 0)',
  [s.sm_down]: {
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  width: 414 + 40,
  height: 896 - 300,
}
const sPhoneInner = {
  borderRadius: '3rem 3rem 0 0',
  position: 'absolute',
  background: `linear-gradient(to bottom right, ${colors.white.concat(
    alpha[100]
  )}, ${colors.white.concat(alpha[0])})`,
  boxShadow: `0px 0px 8px ${colors.black.concat(alpha[8])}`,
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: 414,
  height: 896 - 300 - 24,
}

const sDrop = {
  width: 20,
  height: 20,
  borderRadius: 10,
  backgroundColor: colors.black,
  position: 'absolute',
  // bottom: 896 - 300 - 24 - 32 - 8,
  top: 0,
  left: '50%',
  transform: 'translate(-50%, 0)',
  boxShadow: `0px 0px 8px ${colors.black.concat(alpha[8])}`,

  reflect1: {
    borderRadius: 5,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 10,
    height: 10,
    backgroundColor: 'cyan',
    opacity: 0.3,
  },
  reflect2: {
    borderRadius: 4,
    position: 'absolute',
    top: '30%',
    left: '30%',
    transform: 'translate(-50%, -50%)',
    width: 3,
    height: 3,
    backgroundColor: 'dimgray',
  },
}

const sStatusBar = {
  width: 'calc(100% - 6rem)',
  position: 'absolute',
  top: 40,
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  time: {
    color: colors.black,
    fontWeight: 700,
  },
}

const sCenter = {
  display: 'flex',
  alignItems: 'center',
}

const sTilesContainer = {
  overflow: 'hidden',
  width: '100%',
  height: config.height * 1.3,
  position: 'absolute',
  // display: 'flex',
  // justifyContent: 'center',
  top: 80,
  left: '50%',
  transform: 'translate(-50%, 0)',
  // backgroundColor: 'rgba(255, 0, 0, 0.25)',
}

const sTile = {
  borderRadius: 16,
  backgroundColor: 'blue',
  width: config.width,
  height: config.height,
  marginLeft: '2rem',

  left: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '1rem 0rem 0.75rem 1rem',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 'bolder',
    fontFamily: 'Gilroy',
    paddingBottom: 0,
    letterSpacing: -1,
  },
  subtitle: {
    fontFamily: 'Gilroy',
    fontSize: 10,
    opacity: 0.5,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: 700,
  },
  description: {
    fontSize: 12,
    maxWidth: config.width / 2 + 'px',
    marginTop: '0.875rem',
    opacity: 0.8,
  },
  dot: {
    width: 5,
    height: 5,
    color: 'currentColor',
    opacity: 0.3,
    margin: '0 5px',
  },
  btn: {
    fontFamily: 'Gilroy',
    width: 'max-content',
    padding: '0.5rem 0.75rem',
    borderRadius: '1rem',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    fontWeight: 700,
    marginLeft: '-0.25rem',
  },
  image: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: config.height,
    width: 'auto',
    minWidth: config.height,
    borderRadius: '0 1rem 1rem 0',
  },
  gradient: {
    position: 'absolute',
    width: config.width * 0.6,
    height: config.height,
    borderRadius: '0 1rem 1rem 0',
    top: 0,
    right: 0,
  },
}

const sDots = {
  position: 'absolute',
  backgroundColor: 'transparent',
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: 'max-content',
  height: 'max-content',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  dot: {
    transition: 'opacity 0.3s ease-in-out',
    width: 14,
    height: 14,
    borderRadius: 14,
    color: colors.blue,
    margin: '0 2px',
  },
}

export default ProductSection
